import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                  <p>
                    <b>Total Raised and Donated to Date: $231,073!!!!</b>
                  </p>
                  <p>
                    While we lost Donna in 2021 after a courageous battle with
                    cancer, her lasting legacies include her beautiful heart;
                    her thoughtfulness and concern for others, and her
                    willingness to take action to help. She was simply always
                    thinking of other people.
                  </p>
                  <p>
                    With this in mind, in 2022, we established the Donna V.
                    Toelke Charitable Foundation. Our mission is to raise money
                    every year from Donna’s vast network of family, friends and
                    others who share her incredible charitable spirit, and then
                    to immediately deploy our funds to support causes which were
                    near and dear to her heart. Donna’s charitable giving
                    spanned children’s causes; education; food insecurity;
                    homelessness and fighting cancer among others. Target
                    charities will certainly include those working to address
                    some of these issues, although we will consider all worthy
                    causes. In fact, we invite all contributors to the
                    Foundation to make recommendations for charities to whom we
                    should consider allocating funds. It’s a team effort!
                  </p>
                  <p>
                    In 2022, as part of our inaugural fundraising campaign,
                    thanks to the incredible generosity of our team of
                    contributors, we were able to raise and equally distribute
                    $120,000 in Donna’s name to several great organizations.
                  </p>
                  <p>
                    We kept up the momentum in 2023, and raised and distributed
                    over $100,000 to several amazing organizations. In less than
                    a year and a half, the Foundation has contributed almost a
                    quarter million dollars in Donna’s name to charities who are
                    utilizing this money to make a meaningful difference in the
                    lives of those in need. Well done, all!!!!
                  </p>
                  <p>
                    The following are a list of last year’s recipient
                    organizations:
                  </p>

                  <div className="section-main-description-center-text">
                    <p>
                      <a
                        href="https://www.socialconcern.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Council of Social Concern: The Woburn Food Pantry
                      </a>
                    </p>
                    <p>Neighbors Helping Neighbors</p>

                    <p>
                      <a
                        href="https://www.thenanproject.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TheNANProject
                      </a>
                    </p>
                    <p>Saving Lives One Story At A Time</p>

                    <p>
                      <a
                        href="https://spoonfuls.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lovin’ Spoonfuls
                      </a>
                    </p>
                    <p>Delivering Food with Purpose</p>

                    <p>
                      <a
                        href="https://www.stonehouseinc.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        The Stone House
                      </a>
                    </p>
                    <p>Safe Now. Strong Forever</p>

                    <p>
                      <a
                        href="https://stfrancishouse.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        St. Francis House
                      </a>
                    </p>
                    <p>Rebuilding Lives</p>

                    <p>
                      <a
                        href="https://womenslunchplace.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        The Women’s Lunch Place
                      </a>
                    </p>
                    <p>Dignity is Everything</p>
                  </div>

                  <p>
                    Donna did not want to be forgotten, and we feel that this
                    continues to be a beautiful and fitting way to always
                    remember Donna and keep her in our minds and hearts as we
                    emulate her by working to make the world a better place and
                    easing the burden on those less fortunate. Thank you so much
                    for joining us and becoming part of the Foundation’s
                    mission.
                  </p>
                  <p>
                    The Foundation has been established as a 501(c)(3)
                    organization, so that each person who contributes to the
                    Foundation will be entitled to a charitable deduction upon
                    making his or her contribution. Please also consider adding
                    a matching donation through your employer.
                  </p>
                  <p>Rich, Richie, Sam and Margaret</p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
