import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import axios from "axios";
import qs from "querystring";
import Header from "../components/header";
import Footer from "../components/footer.js";
import { Link, withRouter } from "react-router-dom";
import history from "../history";
import "./page-donate.scss";
import { getCheckout } from "../api/getCheckout";
const currencies = require("../data/currencies.json");

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: "",
      name: "",
      currency: props.currency,
      charityId: props.charityId,
      company: props.company,
      customerCoveringFee: false,
    };

    this.emailUpdate = this.emailUpdate.bind(this);
    this.nameUpdate = this.nameUpdate.bind(this);
    this.handleCustomerCoveringFee = this.handleCustomerCoveringFee.bind(this);
  }

  handleCustomerCoveringFee(event) {
    this.setState({ customerCoveringFee: event.target.checked });
  }

  emailUpdate(event) {
    this.setState({ email: event.target.value });
  }

  nameUpdate(event) {
    this.setState({ name: event.target.value });
  }

  handleSubmit = async (event) => {
    if (this.state.name === "" || this.state.email === "") {
      this.setState({ processing: false });
      alert("Please provide your name and email.");
      event.preventDefault();
      return;
    }
    const { amount } = this.props;
    this.setState({ processing: true });
    event.preventDefault();
    const calculatedAmount = 100 * amount;

    const checkout = await getCheckout(
      calculatedAmount,
      "usd",
      this.state.charityId,
      this.state.name,
      this.state.email,
      this.state.customerCoveringFee
    );
    const stripePromise = loadStripe(checkout.response.publicKey);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: checkout.response.checkoutId,
    });
  };

  render() {
    const { stripe } = this.props;
    return (
      <div>
        <Header />
        <center>
          <div className="header">
            Donate{" "}
            <span style={{ color: "#E05050" }}>${this.props.amount}</span> to{" "}
            <span style={{ color: "#E05050" }}>
              Donna V. Toelke Charitable Foundation
            </span>
          </div>
        </center>
        <div className="donate-page-container">
          <div className="donate-page-container-title">
            <center>
              <form onSubmit={this.handleSubmit}>
                <div className="checkout-form">
                  <input
                    placeholder="Name*"
                    value={this.state.name}
                    onChange={this.nameUpdate}
                  />
                  <input
                    placeholder="Email*"
                    value={this.state.email}
                    onChange={this.emailUpdate}
                  />
                </div>
                <div>
                  <div class="note">
                    Please check this box if you would like to cover the
                    processing fee.
                    <input
                      class="flex"
                      type="checkbox"
                      onChange={this.handleCustomerCoveringFee}
                      value={this.state.customerCoveringFee}
                    />
                  </div>
                </div>
                <button type="submit" disabled={this.state.processing}>
                  {this.state.processing ? "Processing..." : "Donate Now"}
                </button>
              </form>
            </center>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

class DonateForm extends Component {
  render() {
    return (
      <CheckoutForm
        amount={this.props.location.state.amount}
        charityId={parseInt(this.props.location.state.charityId)}
        currency="usd"
        company={this.props.location.state.company}
      />
    );
  }
}

export default withRouter(DonateForm);
