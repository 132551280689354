import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer.js';
import { withRouter } from 'react-router-dom';
import './page-thank-you.scss';

const DonateTransferPage = () => {
	return (
		<div>
			<Header />
			<div className='receipt'>
                <ul>
                <b>To donate via Donor Advised Fund:</b><br/><br/>
                <li>Notify your Donor Advised Fund of your request to make a restricted grant to the Givinga Foundation, ein. 47-4172718</li><br/>
                <li>Include instructions that gift is to be used for the Donna V. Toelke Charitable Foundation</li><br/>
                <li>Please include First and Last Name and either email address or physical address with the transfer</li><br/>
                </ul>
			</div>
			<Footer />
		</div>
	);
}

export default withRouter(DonateTransferPage);